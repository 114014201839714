










import { Component, Prop, Vue } from 'vue-property-decorator'
import BarCodeScanner, {BarCodeEvent, TYPE_EAN, TYPE_LOCATION} from '@/utils/CommandBarCodeScanner'
import {axiosInstance, extractError} from "@/utils/axios";
import {
    LocationLocationDetail,
    ProductApiFactory,
    ProductProductDetail, StockApiFactory, StockStockDetail
} from "@/api";
import {ScannedObjectEvent} from "@/types/inbound";
import {Mixins} from "vue-mixin-decorator";
import {EnvironmentMixin} from "@/mixins/EnvironmentMixin";
import playErrorSound from "@/utils/playErrorSound";

let productApi = ProductApiFactory(undefined, undefined, axiosInstance);
let stockApi = StockApiFactory(undefined, undefined, axiosInstance);


@Component
export default class ProductScanner extends Mixins<EnvironmentMixin>(EnvironmentMixin) {
    developmentProduct: string = '';
    error: string|null = null;
    scanner: BarCodeScanner = new BarCodeScanner();

    @Prop() locationConstraint: undefined|LocationLocationDetail;
    @Prop({default: true, required: false}) displayError: boolean;
    @Prop({default: true, required: false}) displayLocationError: boolean;
    @Prop({default: false, required: false}) packageScanner: boolean;
    @Prop({default: false, required: false}) packageAllowed: boolean;
    @Prop({default: false, required: false}) emitLocationScanEvent: boolean

    packageFound: boolean = false

    developmentScan() {
      this.scanItem(new BarCodeEvent(this.developmentProduct, null));
    }

    async searchPackage(ean: string, reason: any) {
        let result = false
        try {
            let response = await productApi.packageProductsProductCollection(ean);
            let members = response.data['hydra:member']
            for (let product of members) {
                if (!this.packageFound) {
                    let locationVerified = await this.verifyLocationDirectly(product);
                    if (locationVerified) {
                        this.$emit('scanned-product-package', new ScannedObjectEvent(
                            product,
                            this
                        ));
                        this.packageFound = true;
                        this.error = null;
                    }
                }
            }
        } catch (reason) {
            this.error = null;
        }

        if (!this.packageFound) {
            if (this.displayError) {
                playErrorSound();
                this.error = extractError(reason);
            }
            this.$emit('no-results', new ScannedObjectEvent(
                {ean: ean},
                this
            ));
        }

        return result
    }

    scanItem(event: BarCodeEvent) {
        this.packageFound = false;
        if (event.type !== TYPE_EAN) {
            if (this.emitLocationScanEvent && event.type === TYPE_LOCATION) {
                this.$emit('location-scanned', event)
                return
            }
            playErrorSound();
            this.error = 'Dit is geen product';
            return;
        }

        if (this.packageScanner) {
            let packageEan = event.value
            productApi.packageProductsProductCollection(packageEan).then((response) => {
                this.error = null
                this.$emit('scanned-product-package', new ScannedObjectEvent(
                    response.data,
                    this
                ))
            }).catch((reason) => {
                if (this.displayError) {
                    playErrorSound()
                    this.error = extractError(reason)
                }
                this.$emit('no-results', new ScannedObjectEvent(
                    {ean: packageEan},
                    this
                ))
            }).finally(() => {
                return
            })
            return
        }
        if (!this.packageScanner) {
            let ean = event.value
            productApi.queryProductItem(ean, true).then((response) => {
                let product = <ProductProductDetail>response.data;
                Promise.all([
                    this.verifyLocation(product)
                ]).then(values => {
                    this.error = null
                    this.$emit('scanned-product', new ScannedObjectEvent(
                        response.data,
                        this
                    ));
                }).catch(async reason => {
                    if (this.packageAllowed) {
                        await this.searchPackage(ean, reason)
                    }
                    if (!this.packageFound) {
                        playErrorSound();
                        this.$emit('not-on-location', new ScannedObjectEvent(
                            {product: product},
                            this
                        ));
                    }
                });
            }).catch(async (reason) => {
                if (this.packageAllowed) {
                    await this.searchPackage(ean, reason)
                } else {
                    if (this.displayError) {
                        playErrorSound();
                        this.error = extractError(reason)
                    }
                    this.$emit('no-results', new ScannedObjectEvent(
                        {ean: ean},
                        this
                    ))
                }
            }).finally(() => {
                this.packageFound = false
            })
        }
    }

    verifyLocation(product: ProductProductDetail) {
        return new Promise((resolve, reject) => {
            if (!this.locationConstraint) {
                resolve();
            }

            stockApi.productsStockCollection(this.locationConstraint['@id']).then(response => {
                let stockCollection = response.data;

                stockCollection['hydra:member'].forEach((stock: StockStockDetail) => {
                    if (stock.product['@id'] === product['@id']) {
                        if (stock.quantity > 0) {
                            resolve();
                        }
                    }
                });

                let error = 'Dit product hoort niet op deze locatie'
                if (this.displayLocationError) {
                  this.error = error;
                  reject(this.error);
                } else {
                  reject(error)
                }
            }).catch(reason => {
                this.error = extractError(reason)
                reject(reason);
            });
        });
    }

    async verifyLocationDirectly(product) {
        if (!this.locationConstraint) {
            return true;
        }

        let result = false;

        try {
            let response = await stockApi.productsStockCollection(this.locationConstraint['@id']);
            let stockCollection = response.data;
            stockCollection['hydra:member'].forEach((stock) => {
                if (stock.product['@id'] === product['@id'] && stock.quantity > 0) {
                    result = true;
                }
            });

            if (!result) {
                let error = 'Dit product hoort niet op deze locatie *';
                if (this.displayLocationError) {
                    this.error = error;
                }
            }
        } catch (reason) {
            this.error = extractError(reason);
        }

        return result;
    }

    created() {
        this.scanner.init(this.scanItem);
    }

    destroyed() {
        this.scanner.destroy();
    }
}
