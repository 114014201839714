





















import {Component, Prop, Vue} from 'vue-property-decorator'
import { WorkspaceWorkspaceDetail } from "@/api";
import {getModule} from "vuex-module-decorators";
import App from "@/store/modules/App";
import store from '@/store'
import {AuthenticationMixin} from "@/mixins/AuthenticationMixin";
import {Mixins} from "vue-mixin-decorator";

const appStore: App = getModule(App, store);

interface IMixinInterface extends AuthenticationMixin {}

@Component
export default class WorkspaceSelector extends Mixins<IMixinInterface>(AuthenticationMixin) {
    showDialog: boolean = false
    workspaceIri: string|null = null

    get workspace() {return appStore.workspace}
    set workspace(workspace) {
      if (!workspace) {
        return
      }
      if (!this.hasRole('WAREHOUSE_SELECT_WORKSPACE')) {
        return
      }

      this.workspaceId(workspace['@id'])
    }

    get workspaceId() {
      if (this.workspace) {
        return this.workspace['@id'];
      }

      return null
    }

    set workspaceId(workspaceIri)
    {
      if (!workspaceIri) {
        return
      }
      if (!this.hasRole('WAREHOUSE_SELECT_WORKSPACE')) {
        return
      }

      this.workspaceIri = workspaceIri
      this.showDialog = true
    }

    confirmSelection()
    {
        if (!this.workspaceIri) {
            return
        }

        let workspace = this.workspaces.find((workspace) => {return workspace['@id'] === this.workspaceIri})
        appStore.setWorkspace(workspace)
        this.showDialog = false
    }

    get workspaces(): Array<WorkspaceWorkspaceDetail> {return appStore.workspaces}

    mounted() {
        if (!this.workspaces.length) {
            appStore.loadWorkspaces();
        }
    }
}
