










import { Component, Prop } from 'vue-property-decorator'
import BarCodeScanner, {BarCodeEvent, TYPE_LOCATION} from '@/utils/CommandBarCodeScanner'
import {axiosInstance, extractError} from "@/utils/axios";
import {LocationApiFactory, LocationjsonldLocationDetail} from "@/api";
import {ScannedObjectEvent} from "@/types/inbound";
import {Mixins} from "vue-mixin-decorator";
import {EnvironmentMixin} from "@/mixins/EnvironmentMixin";
import playErrorSound from "@/utils/playErrorSound";

let api = LocationApiFactory(undefined, undefined, axiosInstance);

@Component
export default class LocationScanner extends Mixins<EnvironmentMixin>(EnvironmentMixin) {
    developmentLocation: string = '';
    error: string|null = null;
    scanner: BarCodeScanner = new BarCodeScanner();

    @Prop({ default: 'Location' }) placeholder: string|undefined;
    @Prop() mobileConstraint: boolean|undefined;
    @Prop() zoneConstraint: string|undefined;
    @Prop() mancoConstraint: boolean|undefined;
    @Prop() fullConstraint: boolean|undefined;

    developmentScan() {
      this.scanItem(new BarCodeEvent(this.developmentLocation, 'loc'));
    }

    queryBarcode(barcode: string): Promise {
      return api.queryLocationItem(barcode)
    }

    extractLocation(response: object): LocationjsonldLocationDetail {
      return response.data
    }

    extractMetadata(response: object): object {
      return {}
    }

    scanItem(event: BarCodeEvent) {
        if (event.type !== TYPE_LOCATION) {
            playErrorSound();
            this.error = 'Dit is geen locatie';
            return;
        }

        this.queryBarcode(event.value).then((response) => {
          let location = this.extractLocation(response)

          if (
              !this.verifyIsMobile(location)
              || !this.verifyIsInZone(location)
              || !this.verfiyIsManco(location)
              || !this.verfiyIsFull(location)
          ) {
            playErrorSound();
            return;
          }

          this.error = null
          this.$emit('scanned-location', new ScannedObjectEvent(location, this.extractMetadata(response), this))
          this.developmentLocation = ''
        }).catch((reason) => {
          playErrorSound();
          this.error = extractError(reason)
        });
    }

    verifyIsMobile(location: LocationjsonldLocationDetail) {
        if (this.mobileConstraint !== undefined) {
            if (location.mobile !== this.mobileConstraint) {
                if (this.mobileConstraint) {
                    this.error = 'Dit is geen kar locatie';
                } else {
                    this.error = 'Dit is een kar locatie';
                }
                return false;
            }
        }

        return true;
    }

    verifyIsInZone(location: LocationjsonldLocationDetail) {
        if (this.zoneConstraint !== undefined) {
            if (location.zones.length === 0) {
                throw new Error('invalid zone');
            }
            if (location.zones.indexOf(this.zoneConstraint) === -1) {
              this.error = 'Locatie is geen '+this.zoneConstraint+' locatie';
              return false;
            }
        }

        return true;
    }

    verfiyIsManco(location: LocationjsonldLocationDetail) {
      if (this.mancoConstraint !== undefined) {
        if (location.manco !== this.mancoConstraint) {
          if (this.mancoConstraint) {
            this.error = 'Dit is geen manco locatie';
          } else {
            this.error = 'Dit is een manco locatie';
          }

          return false;
        }
      }

      return true;
    }

    verfiyIsFull(location: LocationjsonldLocationDetail) {
      if (this.fullConstraint !== undefined) {
        if (location.full !== this.fullConstraint) {
          if (this.fullConstraint) {
            this.error = 'Dit is geen volle locatie';
          } else {
            this.error = 'Dit is een volle locatie';
          }

          return false;
        }
      }

      return true;
    }

    created() {
        this.scanner.init(this.scanItem);
    }

    destroyed() {
        this.scanner.destroy();
    }
}
